
import { getSystemFilesURL } from "@/helpers";
import Vue from "vue";

export default Vue.extend({
  name: "error401",
  data() {
    return {};
  },
  computed: {
    homeIcon(): string[] {
      return ["fal", "home"];
    },
    get401ImageUrl(): string {
      return getSystemFilesURL("401");
    }
  }
});
